/* These media query styles are just for CFTSetup and
   they override parts of pages/style/Screen.css */

/* changes for full screen Pixel 2 XL */
@media (min-width: 400px) and (max-height: 850px) {
  /* shrink the Tracker */
  .tracker-form-split.CFTSetupTracker
  .Tracker > svg {
    height: 36vh;
    width: 36vh;
  }
}

@media (min-width: 760px) {    /* changes for ipad/tablet sizes */
  .screen {
    /* background-color: antiquewhite; */
    width: 92%;
    padding: 0;
  }

  .tracker-form-split.CFTSetupTracker
  .Tracker > svg {
    height: 60vmin; /* set height to just less than the Subcategory/Form group height  */
    width: 60vmin;
    /* outline: 2px solid green; */
  }
}

@media (min-width: 1024px) {    /* changes for desktop sizes */
  .screen {
    /* background-color: azure; */

    /* When too much space vertically, spread out
       the flex items over the full screen height,
       but leave room for the FormFooter
       which has a fixed position at the bottom. */
    height: 90vh !important;
    justify-content: space-around !important;
  }

  /* put tracker on left and the form on the right */
  .tracker-form-split {
    flex-direction: row;
    justify-content: center;
    gap: 10vmin;

    /* outline: 2px solid blue; */
  }

  .Tracker > svg {
    height: 50vmin; /* set height to just less than the Subcategory/Form group height  */
    width: 50vmin;
    /* outline: 2px solid green; */
  }
}

@media (min-width: 1900px) {
  /* scale Tracker svg to be a % of the min of viewport width and viewport height */
  .Tracker > svg {
    height: 50vmin; /* set height to just less than the Subcategory/Form group height  */
    width: 50vmin;
  }

}

/* handle short phones like nHD Galaxy S7 and iPhone SE/6/7/8  (375x667)

Specify a max-width to prevent selecting a desktop.
*/
@media (max-height: 700px) and (max-width: 500px) {
  .tracker-form-split {
    gap: .5em; /* mobile: vertical space between Tracker and Subcategories/Form */
  }
  /* shrink the Tracker */
  .tracker-form-split.CFTSetupTracker
  .Tracker > svg {
    height: 30vh;
    width: 30vh;
  }
}


/* handle portrait tablets like ipad mini (768x1024),
   which are shorter (more square) than a regular phone aspect ratio.
*/
@media (height: 1024px) and (width: 768px) {
}
