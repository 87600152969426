:root {
  --black: #000000;
  --grey-dark: #333333;
  --blue-cashflow: #4F81B4;
  --blue-cashflow-inactive: #1684c74c;

  /* MoneyFlow setup: Next button, Subcat list border */
  --green-moneyflow: #68cd74;

  --lime-assets: #75bb43;
  --lime-assets-inactive: #75bb434c;
  --orange-debts: #e46e26;
  --orange-debts-inactive: #e46d2540;
  --grey-progress-bar-text: #6f6e6e80;
  --grey-progress-bar-background: rgba(221, 221, 221, 0.5);
  --grey-subcat-text: #656565;
  --white: #ffffff;

  --font-size-m: 1.25rem;

  --font-family-title: TitleFont;

  --padding-s: 10px;
  --padding-m: 16px;

  --shrink: .8;

  /* on mobile, height is 6.3vh = 58px/921px */
  --moneyflow-logo-height: 6.3vh;
  --moneyflow-logo-height-no-units: 6.3;
}
