/* COMPONENTS */

.expense-counter-number {
    font-size: 16px;
    margin: 3px; /* remove all margins to fit two divs in the container */
    display: inline-block; /* display block elements in one line */
  }
  
  .expense-counter-title {
    font-size: 16px;
    margin: 3px;
    display: inline-block;
  }
  