/* Use this css file if you want ipad and desktop to *look* similar to ipad.
   If you want ipad and desktop to have their own responsive styles,
   use Screen.css instead.
 */

.screen {
  align-items: center;
  background-color: var(--white);
  border: 1px none;
  display: flex;
  flex-direction: column;
  /* When not enough space vertically,
     pack the flex items at the start. */
  justify-content: flex-start;
  width: 100%;
  position: relative;
  max-width: 1500px;
  margin: 0 auto;
  padding: 0 var(--padding-m);
  /* outline: 1px solid green; */
}

.tracker-form-split {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 1em; /* mobile: vertical space between Tracker and Subcategories/Form */
  width: 100%;
  position: relative;
  /* outline: 1px solid fuchsia; */
}

.Tracker > svg {
  /* on mobile, scale Tracker svg to be the full viewport width - screen gutters */
  height: calc(100vw - 2 * var(--padding-m));
  width: calc(100vw - 2 * var(--padding-m));
  /* outline: 2px solid green; */
}

@media (min-width: 760px) {    /* changes for ipad/tablet sizes */
  .screen {
    /* background-color: antiquewhite; */
    width: 92%;
    padding: 0;
  }

  .Tracker > svg {
    height: 80vmin; /* set height to just less than the Subcategory/Form group height  */
    width: 80vmin;
    /* outline: 2px solid green; */
  }
}

@media (min-width: 1024px) {    /* changes for desktop sizes */
  .screen {
    /* background-color: azure; */

    /* When too much space vertically, spread out
       the flex items over the full screen height,
       but leave room for the FormFooter
       which has a fixed position at the bottom. */
    height: 90vh !important;
    justify-content: space-around !important;
  }

  /* put tracker on left and the form on the right */
  .tracker-form-split {
    flex-direction: row;
    justify-content: center;
    gap: 10vmin;

    /* outline: 2px solid blue; */
  }

  .Tracker > svg {
    height: 50vmin; /* set height to just less than the Subcategory/Form group height  */
    width: 50vmin;
    /* outline: 2px solid green; */
  }
}

@media (min-width: 1900px) {
  /* scale Tracker svg to be a % of the min of viewport width and viewport height */
  .Tracker > svg {
    height: 50vmin; /* set height to just less than the Subcategory/Form group height  */
    width: 50vmin;
  }

}

/* handle portrait tablets like ipad mini (768x1024),
   which are shorter (more square) than a regular phone aspect ratio.
*/
@media (height: 1024px) and (width: 768px) {
}

/* IPAD-LOOK-ONLY:  Force everything to be <= 744px */
/* Intentionally using 744px, instead of 800px, so that this rule
   catches all ipads and desktops. */
@media (min-width: 744px) {
  .screen {
    max-width: 744px;
  }

  /* Ant Design Mobile Floating Panel */
  .adm-floating-panel {
    width: 744px;
    left: calc((100% - 744px) / 2); /* centered */
  }
}
