/*@import url("https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css");
*/
*,
*::before,
*::after {
  box-sizing: border-box;
}

:root {
  font-size: 16px;
}

.screen a {
  display: contents;
  text-decoration: none;
}
body{
    margin: 0;
    padding: 0;
}


/***************************************************
* The accent font poetson one
****************************************************/
@font-face {
  font-family: AccentFont;
  src: url(assets/font/PoetsenOne-Regular.ttf);
}

/***************************************************
* The main variable font
****************************************************/
@font-face {
  font-family: PrimaryVariableFont;
  src: url(assets/font/Nunito-VariableFont_wght.ttf);
}

@font-face {
  font-family: PrimaryItalicVariableFont;
  src: url(assets/font/Nunito-Italic-VariableFont_wght.ttf);
}


/***************************************************
* The main static fonts, used as a fallback.
****************************************************/
@font-face {
  font-family: ExtraLightFont;
  /* Thin == Extra-light == 100 */
  src: url(assets/font/static/Nunito-ExtraLight.ttf);
}

@font-face {
  font-family: LightFont;
  /* Light == 200 */
  src: url(assets/font/static/Nunito-Light.ttf);
}

@font-face {
  font-family: RegularFont;
  /* Regular == 400 */
  src: url(assets/font/static/Nunito-Regular.ttf);
}

@font-face {
  font-family: CondensedFont;
  /* Regular == 400 */
  src: url(assets/font/static/Nunito-Regular.ttf);
}

@font-face {
  font-family: MediumFont;
  /* Medium == 500 */
  src: url(assets/font/static/Nunito-Medium.ttf);
}

@font-face {
  font-family: SemiBoldFont;
  /* SemiBold == Demi == 600 */
  src: url(assets/font/static/Nunito-SemiBold.ttf);
}

@font-face {
  font-family: TitleFont;
  /* SemiBold == Demi == 600 */
  src: url(assets/font/static/Nunito-SemiBold.ttf);
}

@font-face {
  font-family: BoldFont;
  /* Bold == 700 */
  src: url(assets/font/static/Nunito-Bold.ttf);
}

@font-face {
  font-family: ExtraBoldFont;
  /* ExtraBold == 800 */
  src: url(assets/font/static/Nunito-ExtraBold.ttf);
}

@font-face {
  font-family: HeavyFont;
  /* Heavy == Black == 900 */
  src: url(assets/font/static/Nunito-Black.ttf);
}


/***************************************************
* Previously used fonts
****************************************************/
@font-face {
  font-family: AvenirCondensedFont;
  src: url(assets/font/AvenirNextLTProCondensed.otf);
}

@font-face {
  font-family: AvenirTitleFont;
  src: url(assets/font/AvenirNextLTProDemi.otf);
}

.screen {
  font-family: TitleFont;
}

#help-text {
  text-align: center;
  color: #333333;;
}

.editBurger{
  padding:0px;
  margin:0px;
  vertical-align: bottom;
}

table {
  border-collapse: collapse;
}
tr.row {
  border-bottom: solid;
  border-width: 1px 0;
  border-color:#000;
}
