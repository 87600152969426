/* Since, Ant Design forces ant-tour to be
    520px wide, this is a problem for mobile.
    This fix makes the tour the width of the mobile screen
    minus some padding on the sides.
*/
@media (max-width: 520px) {
  .ant-tour-content {
    width: 90vw;
    position: fixed !important;
    left: 5vw !important;
  }
}
