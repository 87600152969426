.form-wrapper {
  padding: 0;
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 22px;
  position: relative;
  width: 100%;
  max-width: 430px;

  /* top: -50px;  Don't do this to decrease the space below the tracker and above the form. */
  /* Instead set styles/Screen.css' .tracker-form-split's gap property. */
}

.category-title-group {
  height: 4rem;
  display: flex;
  align-items: baseline;
  margin-top: 1em;
  margin-bottom: -2.3em;
}

.category-title-icon {
  height: 3em;
  padding-right: 1.4em;
}

.category-title-text {
  color: var(--grey-dark);
  font-family: PrimaryVariableFont, TitleFont, sans-serif;
  font-size: 2rem; /* 32/16 */
  font-weight: 500;
  line-height: 1;
  margin: 0;
  padding: 0;
  text-align: center;
}

.mfo-radio {
  display: block;
  position: relative;
  height: 72px;
  /* on mobile height is 8.5vh = 72px/844px */
  width: min(430px, 100vw - 2 * var(--padding-m));
  margin-bottom: 1em;  /* vertical space between radios */
  border-radius: 16px;
  background-color: #eef7ee;

  font-family: TitleFont;
  font-weight: 500;
  font-size: 1.25rem; /* 20/16 */
  line-height: 1.5rem; /* 24/16 */
  text-align: left;
  letter-spacing: 0.1px;
  color: var(--grey-dark);

  @media (min-width: 760px) {    /* changes for ipad/tablet sizes */
    max-width: min(430px, 92vw);
    height: 8.5vh;
  }
  @media (min-width: 1024px) {    /* changes for desktop sizes */
    max-width: min(750px, 30vw);
  }
}

div.form-wrapper div.adm-radio-content {
  @media (min-width: 760px) {    /* changes for ipad/tablet sizes */
    font-size: 2.83vh !important;
     /* 8.5 / 3 = 2.83 */
    line-height: 3.4vh !important;
    /* 2.83 * 1.5 / 1.25  = 3.4 */
  }
}

div.form-wrapper div.adm-radio-custom-icon {
  /* Would be 24/20 = 1.2em, if it actually used mfo-radio's font-size.
     Instead .adm-radio-custom-icon sets font-size to be --icon-size
     --icon-size is already 24px.  So use 24/24 = 1em */
  margin-left: 1em;
}

div.form-wrapper label.adm-radio-checked div.adm-radio-custom-icon > div {
  background-color: #68cd74 !important;
  border-color: #fafafa !important;
}

div.form-wrapper label.adm-radio-checked {
  background-color: #d9f7bd;
}

form#AgesOfDependents div.adm-grid {
  @media (max-width: 428px) and (max-height: 844px) {
    --gap-vertical: 0;
  }
}

form#Dependents div.adm-stepper {
  gap: 1em;
  align-items: center;
  width: 290px;
}

/* the input in the middle of the stepper,
   which holds the number */
form#Dependents input.adm-input-element {
  height: 81px;
  width: 89px;
  min-height: 1em;
  background-color: #d9f7bd;
  border: none;
  color: #68cd74;
  border-radius: 18px;
  font-family: CondensedFont;
  font-style: normal;
  font-weight: 700;
  font-size: 63px;
  line-height: 81px;
  padding-top: 6px; /* to vertically center text, for this font */
}

form#Dependents button.adm-stepper-minus,
form#Dependents button.adm-stepper-plus {
  width: 80px;   /* Figma: 53 x 69, but too narrow to click on */
  height: 69px;

  border: 1px solid #b3b3b3;
  border-radius: 14px;

  font-family: CondensedFont !important;
  font-style: normal;
  font-weight: 700;
  /* plus button in Figma */
  /*
  font-size: 44px;
  line-height: 51px;
  */
  /* minus button in Figma */
  font-size: 40px;
  line-height: 47px;

  color: #68cd74;
}

.categoryIcon {
  margin-top: 1em;

  /*  make same as .category-title-icon which is used for subcat */
  height: 3em;
  width: 3em;
  margin-right: 1.4em;

  /* or make same as text height...
  height: 1.875em;
  width: 1.875em;
  margin-right: .4em;
  */
}

/* to change the color of the icons in the money wheel setup */
/* #25b764 is the color of the money wheel */
.categoryIcon path {
  /* fill: #4F81B4;
  color: #4F81B4; */
}

.categoryIcon[disabled] path  {
  /* fill: #DDDDDD;
  color: #DDDDDD; */
}

@media (min-width: 760px) and (min-height: 800px) {
  :root {
    font-size: 24px; /* scales all font-sizes that use rem units */
  }
}

@media (min-width: 760px) and (max-height: 1200px) and (orientation: portrait) {
  /* changes for portrait ipad/tablet sizes */
  .form-wrapper {
    max-width: 100%;
  }

  .categoryIcon {
    margin-top: 2em;
    height: 140px;
    width: 140px;
  }
}

@media (min-width: 1024px) and (orientation: landscape) {
  /* changes for smaller landscape desktop sizes */
  .form-wrapper {
    /* 50vmax so that language selector buttons don't overlap */
    max-width: max(50%, 50vmax);
  }
}

@media (min-width: 1024px) and (min-height: 1080px) and (orientation: landscape) {
  /* changes for landscape desktop sizes */
  .form-wrapper {
    max-width: max(50%, 50vmax);
    /* outline: 2px solid orange; */
  }
}

@media (min-width: 2500px) and (min-height: 1400px) {
  /* changes for largest desktop sizes */
  .category-title-group {
    /* Move category title down */
    height: 3rem;
    margin-bottom: -1.3em;
  }
  .category-title-text {
    /* Make font smaller to handle text like "Home & Garden" */
    font-size: calc(0.7 * 3rem);
    line-height: calc(0.7 * 4.125rem);
  }
}

/* Handle narrow phones like iPhone 13/ 13 Pro / 14  (390x844)
*/
@media (max-width: 399px) and (min-height: 700px) {
  .category-title-group {
    height: 3rem;
    margin-bottom: -1.3em;
  }
  .category-title-text {
    /* Make font smaller to handle text like "Home & Garden" */
    font-size: calc(0.9 * 3rem);
    line-height: calc(0.9 * 4.125rem);
  }
}

/* Handle short phones like nHD Galaxy S7 and iPhone SE/6/7/8  (375x667).

Specify a max-width to prevent selecting a desktop.

Shrink everything down by the --shrink factor.
*/
@media (max-width: 500px) and (max-height: 700px) {
  :root {
    font-size: calc(var(--shrink) * 16px);
  }

  .category-title-group {
    min-width: calc(var(--shrink) * 254px);
  }
}

::selection {
  background-color: salmon;
  color: white;
}
